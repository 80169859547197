<template>
  <!-- eslint-disable  -->
  <div v-for="(item, index) in cartItems" :key="index">
    <CartItem
      :photo="item.photo"
      :item="item.item"
      :price="item.price"
      :color="item.color"
      :message="item.message"
      :index="index"
      :cart="true"
    />
  </div>
  <Button
    v-if="cartItems.length"
    label="PLACE YOUR ORDER"
    class="p-button-warning but place"
    @click="prepare"
  />
  <Button
    label="Back to Main Menu"
    class="p-button-warning but"
    @click="main"
  />
  <Dialog header="Place Order" v-model:visible="dialogWin">
    <p>
      Once you press "Place Order", an email will be send to our representative
      with your order.
    </p>
    <p>We will contact you using the email you provided when you signed in</p>
    <p>If you want us to call you via phone, please provide your phone</p>
    <div class="p-my-3">
      <InputNumber
        v-model="phone"
        :useGrouping="false"
        placeholder="Phone Number"
      />
    </div>
    <div v-if="!loading">
      <Button
        label="Place Order"
        class="p-button-warning p-mr-3"
        @click="send"
      />
      <Button
        label="Close"
        class="p-button-danger"
        @click="dialogWin = false"
      />
    </div>
    <div v-else>
      <ProgressSpinner
        style="width: 30px; height: 30px"
        strokeWidth="4"
        fill="#FFFFFF"
        animationDuration="1.5s"
      />
      <span style="font-size: 2rem" class="p-ml-2">Loading....</span>
    </div>
  </Dialog>
  <Dialog header="Confirmation" v-model:visible="submitted">
    <h4>Your order has been Submitted</h4>
    <div>
      <Button label="Close" class="p-button-danger but" @click="leave" />
    </div>
  </Dialog>
</template>

<script>
import { onMounted, ref } from "vue";
import getCart from "../modules/getCart";
import updateField from "../modules/updateField";
import createOrder from "../modules/createOrder";
import { auth } from "../firebase/config";
import CartItem from "../components/cartItem";
import axios from "axios";
import { useRouter } from "vue-router";
export default {
  components: { CartItem },
  setup() {
    const router = useRouter();
    const dialogWin = ref(false);
    const cartItems = ref([]);
    const phone = ref(null);
    const submitted = ref(false);
    const loading = ref(false);
    const doc = `${auth.currentUser.email}-${auth.currentUser.uid}`;
    onMounted(async () => {
      const { item, getCartError } = await getCart(doc);
      if (!getCartError.value) {
        cartItems.value = item.value;
      } else {
        window.alert("Cart can not being loading !!!!");
      }
    });
    const main = () => {
      window.location.href = "/#main";
    };
    const prepare = () => {
      dialogWin.value = true;
    };
    const send = async () => {
      loading.value = true;
      let doc = `${auth.currentUser.email}-${auth.currentUser.uid}`;
      let url = process.env.VUE_APP_URL;
      let rand = Math.floor(Math.random() * 100000).toString();
      if (phone.value) {
        await updateField(doc, "phone", phone.value);
      }
      const { orderError } = await createOrder(doc, rand, cartItems.value);
      if (orderError.value) {
        window.alert(
          "order failed, please check your net connection and try again"
        );
        dialogWin.value = false;
      } else {
        const res = await axios.post(`${url}/seller`, {
          userId: doc,
          email: auth.currentUser.email,
          phone: phone.value,
          orderNumber: rand.toString(),
        });
        if (!res.data) {
          window.alert("data is not submitted");
        } else {
          await updateField(doc, "cart", []);
          dialogWin.value = false;
          submitted.value = true;
          loading.value = false;
          phone.value = null;
          const ress = await axios.post(`${url}/confirmation`, {
            email: auth.currentUser.email,
            message: `Your order has been Submitted, we will contact you in one to two business day, Your order number is ${rand.toString()}`,
          });
          if (!ress.data) {
            console.log("data is not submitted");
          }
        }
      }
    };
    const leave = () => {
      router.push({ name: "Home" });
    };
    return {
      cartItems,
      main,
      prepare,
      dialogWin,
      send,
      phone,
      submitted,
      leave,
      loading,
    };
  },
};
</script>

<style scoped>
.but {
  display: block;
  width: 70%;
  margin: 20px auto;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 1.5rem;
  font-family: "Philosopher", sans-serif;
}
.place {
  background: #b18800;
  color: white;
}
</style>
