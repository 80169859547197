import { db } from "../firebase/config";
import { ref } from "vue";
const createOrder = async (doc, rand, cartItems) => {
    const orderError = ref(false)
    try {
        await db.collection('users').doc(doc).collection('orders').doc(rand).set({ cart: cartItems })
    } catch (err) {
        orderError.value = err.message
    }
    return { orderError }
}
export default createOrder